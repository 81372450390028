import React from 'react';
import styles from './Section.module.scss';

export interface ISectionProps {
  children: JSX.Element[] | JSX.Element;
  alternateBackgroundColor?: boolean;
}

export default function Section(props: ISectionProps) {
  const { children, alternateBackgroundColor } = props;
  return (
    <section className={`${styles.root} ${alternateBackgroundColor ? styles.alternateBackgroundColor : ''}`}>
      <div className={styles.wrapper}>
        {children}
      </div>
    </section>
  );
}