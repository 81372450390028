import React from 'react';

export function PhoneWithTextBubble() {
  return (
    <svg version="1.1" id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="122.879px" height="122.785px" viewBox="0 0 122.879 122.785" enable-background="new 0 0 122.879 122.785" xmlSpace="preserve" fill="currentColor">
      <g>
        <path d="M29.789,59.399c3.635,6.556,7.821,12.852,13.274,18.597c5.452,5.777,12.236,11.035,21.031,15.515 c0.649,0.324,1.266,0.324,1.817,0.097c0.844-0.324,1.688-1.006,2.532-1.85c0.649-0.649,1.46-1.688,2.305-2.824 c3.375-4.447,7.563-9.964,13.469-7.205c0.13,0.064,0.228,0.13,0.357,0.194l19.7,11.327c0.065,0.032,0.131,0.098,0.195,0.13 c2.597,1.785,3.667,4.544,3.699,7.66c0,3.181-1.168,6.75-2.888,9.769c-2.272,3.992-5.615,6.621-9.478,8.374 c-3.667,1.688-7.757,2.597-11.685,3.181c-6.166,0.908-11.943,0.324-17.851-1.493c-5.777-1.784-11.587-4.738-17.948-8.665 l-0.454-0.293c-2.921-1.817-6.069-3.765-9.152-6.068c-11.328-8.537-22.849-20.87-30.347-34.437 c-6.297-11.392-9.737-23.693-7.854-35.41c1.039-6.426,3.797-12.268,8.601-16.13c4.187-3.375,9.834-5.226,17.136-4.576 c0.844,0.064,1.59,0.552,1.98,1.265l12.625,21.356c1.851,2.402,2.077,4.771,1.071,7.14c-0.844,1.948-2.531,3.733-4.836,5.42 c-0.681,0.584-1.492,1.168-2.336,1.785c-2.824,2.045-6.037,4.414-4.934,7.206L29.789,59.399L29.789,59.399L29.789,59.399z M107.916,18c2.594,0,4.696,2.103,4.696,4.696s-2.103,4.696-4.696,4.696s-4.696-2.103-4.696-4.696S105.322,18,107.916,18 L107.916,18z M75.697,17.983c2.6,0,4.707,2.107,4.707,4.706s-2.107,4.705-4.707,4.705s-4.704-2.106-4.704-4.705 S73.098,17.983,75.697,17.983L75.697,17.983z M92.384,17.983c2.6,0,4.707,2.107,4.707,4.706s-2.107,4.705-4.707,4.705 s-4.705-2.106-4.705-4.705S89.784,17.983,92.384,17.983L92.384,17.983z M73.012,0c-3.518,0-6.527,1.238-9.031,3.714 s-3.714,5.486-3.714,9.032v22.368c0,3.545,1.237,6.555,3.714,9.032c2.476,2.476,5.486,3.713,9.031,3.713h10.472 c-0.731,2.813-1.632,5.486-2.757,7.99c-1.098,2.533-2.982,4.952-5.571,7.259c4.98-1.294,9.397-3.235,13.308-5.796 c3.884-2.532,7.26-5.711,10.045-9.454h11.626c3.518,0,6.528-1.266,9.032-3.713c2.504-2.477,3.713-5.487,3.713-9.032V12.746 c0-3.518-1.237-6.528-3.713-9.032C116.689,1.21,113.68,0,110.134,0C98.822,0,84.324,0,73.012,0L73.012,0L73.012,0z"/>
      </g>
    </svg>
  );
}