import React from 'react';
import styles from './About.module.scss';
import { Section } from '../Section';

export interface IAboutProps {

}

export default function About(props: IAboutProps) {
  return (
    <Section>
      <h2 id="about-us">About Us</h2>
      <p>
        At the Canadian Charcuterie Company Craftsmanship finds Artistry. All our Boards are made 
        from Live Edge wood, and we Fractal burn our boards 
        using <a href="https://en.wikipedia.org/wiki/Fractal_burning">"The Lichtenberg Device"</a>.
        These
        boards are One of a Kind works of Art. Each piece of Wood in Our Collection is Meticulously 
        hand crafted, making no two pieces alike.

        We are committed to maintaining the highest standards of quality in every aspect
        of our production process, from material selection to the perfect finishing touches.
        At Canadian Charcuterie Company, we believe that every piece we create should not
        only serve its practical purpose but also be a testament to the beauty of 
        handcrafted woodworking.
      </p>
      <h3 id="dwayne-higgins">About the Founder</h3>
      <p>
        Canadian Charcuterie Company was founded by Dwayne Higgins, a man whose life's journey
        has taken him from the glitz and glamour of Hollywood to the struggles of addiction and 
        despair, and finally to a place of hope and redemption.
      </p>
      <p>
        Dwayne was a military brat, living in Europe, and traveled the world from a young age, eventually
        landing in Los Angeles. With a career proping and designing movie sets, this brought him into contact
        with some of the biggest names in Hollywood, Dwayne's life seemed like a dream come true.
        However, behind the scenes, he battled with addiction and found himself on a path of destruction, which lead
        to Dwayne being deported back to Canada.
      </p>
      <p>
        In the midst of the darkness, Dwayne encountered Christ like a beacon of light, and it 
        was through his encounter that his life was transformed. Through Christ, Dwayne was 
        given the strength and hope to overcome his struggles with addiction and despair. 
        Today, Dwayne is dedicated to sharing his journey of personal redemption and spreading the 
        message of hope.
      </p>
    </Section>
  );
}
