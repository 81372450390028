import IJobInfo from "./IJobInfo";
import jobInfos from "./JobInfos.generated";

export interface IContactInfo {
  email: string;
  phoneNumber: string;
}

export default class WelcomeDataProvider {

  public getJobs(): Promise<IJobInfo[]> {
    return Promise.resolve(jobInfos);
  }

  public getContactInfo(): Promise<IContactInfo> {

    // Complicated to try and avoid sniffing
    const EMAIL_ALIAS : string = 'rdhiggins';
    const EMAIL_DOMAIN : string = 'outlook.com';
    const PHONE_SEGMENT_1 : number = 604;
    const PHONE_SEGMENT_2 : number = 374;
    const PHONE_SEGMENT_3 : number = 6047;

    return Promise.resolve({
      email: `${EMAIL_ALIAS}@${EMAIL_DOMAIN}`,
      phoneNumber: `${PHONE_SEGMENT_1}-${PHONE_SEGMENT_2}-${PHONE_SEGMENT_3}`
    });
  }

}