import React, { useEffect, useState } from 'react';
import styles from './Contact.module.scss';
import { Section } from '../Section';
import { PhoneWithTextBubble, Email } from '../../icons';
import { WelcomeDataProvider, type IContactInfo } from '../../data';

export interface IContactProps {
}

// TODO: take from some context or something.
const dataProvider : WelcomeDataProvider = new WelcomeDataProvider();

export default function Contact(props: IContactProps) {

  const [email, setEmail] = useState<string | undefined>(undefined);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);

  useEffect(() => {
    dataProvider.getContactInfo()
      .then((contactInfo: IContactInfo) => {
        setEmail(contactInfo.email);
        setPhoneNumber(contactInfo.phoneNumber);
      });
  }, [setEmail, setPhoneNumber]);

  return (
    <Section>
      <h2 id="contact">Contact Us</h2>
      <p>Get your own unique, hand-crafted charcuterie board by contacting us:</p>
      <div className={styles.linksContainer}>
        <a className={styles.link} href={`mailto:${email}`}>
          <Email />
          <span className={styles.text}>
            Send us an E-mail at <strong>{email}</strong>
          </span>
        </a>
        <a className={styles.link} href={`tel:+${phoneNumber}`}>
          <PhoneWithTextBubble />
          <span className={styles.text}>
            Give us a call at <strong>{phoneNumber}</strong>
          </span>
        </a>
      </div>
    </Section>
  );
}
