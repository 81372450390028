import React, { useCallback } from 'react';
import { Image } from '../../Image';
import styles from './Thumbnail.module.scss';

export interface IThumbnailProps {
  image: string;
  index: number;
  isSelected: boolean;
  onSelected: (index: number) => void;
}

export default function Thumbnail(props: IThumbnailProps) {
  const { image, index, isSelected, onSelected } = props;

  const onClick = useCallback((): void => {
    onSelected(index);
  }, [index, onSelected]);

  return (
    <div className={`${styles.root} ${isSelected ? styles.selected : ''}`}>
      <Image
        src={image}
        className={styles.image}
        onClick={onClick}
      />
      <div className={styles.overlay} />
    </div>
  );
}