import React, { useCallback, useRef, useState } from 'react';
import styles from './JobCarousel.module.scss';
import { JobCard } from '../JobCard';
import exampleImage from '../../res/images/carousel/CarouselImage2.jpg';
import { IJobInfo } from '../../data';
import { ChevronLeft, ChevronRight } from '../../icons';

export interface IJobCarouselProps {
  jobs: IJobInfo[];
  onJobSelected: (jobInfo: IJobInfo) => void;
}

type ScrollPosition =
  | 'left'
  | 'middle'
  | 'right';

export default function JobCarousel(props: IJobCarouselProps) {
  const { jobs, onJobSelected } = props;

  const scrollPaneRef = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>('left');

  const scrollRight = useCallback(() => {
    if (!scrollPaneRef.current)
      return;

    const scrollPane : HTMLDivElement = scrollPaneRef.current;

    scrollPane.scrollBy(scrollPane.clientWidth, 0);
    console.log('scrollPane.widths: ', scrollPane.scrollLeft, scrollPane.scrollWidth - scrollPane.clientWidth);
  }, [scrollPaneRef]);

  const scrollLeft = useCallback(() => {
    if (!scrollPaneRef.current)
      return;

    const scrollPane : HTMLDivElement = scrollPaneRef.current;

    scrollPane.scrollBy(-scrollPane.clientWidth, 0);
  }, [scrollPaneRef])

  const onScroll = useCallback(() => {
    if (!scrollPaneRef.current)
      return;

    const scrollPane : HTMLDivElement = scrollPaneRef.current;

    setScrollPosition(oldScrollPosition => {
      if (scrollPane.scrollLeft === 0)
        return 'left';
      else if (scrollPane.scrollLeft === scrollPane.scrollWidth - scrollPane.clientWidth)
        return 'right';
      else
        return 'middle';
    });
  }, [setScrollPosition]);

  return (
    <div className={styles.root}>
      <button 
        className={`${styles.scrollLeftButton} ${scrollPosition !== 'left' ? styles.visible : ' '}`}
        onClick={scrollLeft}
      >
        <ChevronLeft />
      </button>
      <div
        ref={scrollPaneRef}
        className={styles.scrollPane}
        onScroll={onScroll}
      >
        {jobs.map((job: IJobInfo) => (
          <JobCard
            key={job.jobTitle}
            jobTitle={job.jobTitle}
            imageSrc={job.thumbnailImageUrl}
            onJobSelected={() => onJobSelected(job)}
          />
        ))}
      </div>
      <button 
        className={`${styles.scrollRightButton} ${scrollPosition !== 'right' ? styles.visible : ' '}`}
        onClick={scrollRight}
      >
        <ChevronRight />
      </button>
    </div>
  );
}