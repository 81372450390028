import React from 'react';
import styles from './TitleArea.module.scss';
import logo from '../../res/images/logo256.png';
import background from '../../res/images/welcome/board.jpg';

export interface ITitleAreaProps {

}

export default function TitleArea(props: ITitleAreaProps) {
  return (
    <div 
      className={styles.root}
      style={{ backgroundImage: `url(${background})` }}
    >
      <h1>
        <span>Canadian</span>
        <span>Charcuterie</span>
        <span>Company</span>
      </h1>
      <img
        className={styles.logo} 
        src={logo}
        alt="Canadian Charcuterie Company logo"
      />
    </div>
  );
}