import React, { useCallback, useEffect, useState } from 'react';
import styles from './Image.module.scss';

export interface IImageProps {
  src: string | undefined;
  alt?: string;
  className?: string;
  onClick?: () => void;
}

export default function Image(props: IImageProps) {
  const { src, alt, className, onClick } = props;

  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    setLoading(true);
  }, [src]);

  const onLoad = useCallback(
    () => {
      setLoading(false)
    },
    [setLoading]
  );

  return (
    <div className={`${styles.root} ${className ?? ''} ${onClick ? styles.hasOnClick : ''}`}>
      <img
        src={src}
        alt={alt ?? ''}
        className={styles.image}
        onClick={onClick}
        onLoad={onLoad}
      />
      <div 
        className={`${styles.cover} ${loading ? styles.loading : ''}`}
      />
    </div>
  );
}