import React from 'react';
import styles from './NavBar.module.scss';
import logo from '../../res/images/logo128.png';
import { NavLink } from 'react-router-dom';

export interface INavBarProps {
  inline?: boolean;
}

interface INavLink {
  href: string;
  text: string;
  target?: React.HTMLAttributeAnchorTarget;
}

const NAV_LINKS: INavLink[] = [
  {
    href: '/about',
    text: 'About us',
  },
  {
    href: '/contact',
    text: 'Contact',
  },
  {
    href: '/giving-back',
    text: 'Giving Back',
  },
  {
    href: 'https://www.etsy.com/ca/shop/CdnCharCo',
    text: 'Etsy',
    target: '_blank',
  }
]

export default function NavBar(props: INavBarProps) {

  const { inline } = props;

  return (
    <nav className={`${styles.root} ${inline ? styles.inline : ''}`}>
      {!inline && (
        <NavLink 
          to="/"
          className={styles.homeLink}
        >
          <img 
            className={styles.logo}
            src={logo}
            alt="logo"
          />
          <h1 className={styles.title} >
            <span>Canadian</span>
            <span>Charcuterie</span>
            <span>Company</span>
          </h1>
        </NavLink>
      )}
      <div className={styles.navLinks}>
        {NAV_LINKS.map((navLink: INavLink) => (
          <NavLink
            to={navLink.href}
            className={styles.navLink}
            target={navLink.target}
          >
            {navLink.text}
          </NavLink>
        ))}
      </div>
    </nav>
  );
}