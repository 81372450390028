import React from 'react';
import styles from './ContactPage.module.scss';
import { Footer } from '../../components/Footer';
import { NavBar } from '../../components/NavBar';
import { Contact } from '../../components/Contact';

export default function ContactPage() {
  return (
    <div className={styles.root}>
      <NavBar />
      <div className={styles.mainContent}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}