import React from 'react';
import { Welcome } from '../../components/Welcome';
import { About } from '../../components/About';
import { Contact } from '../../components/Contact';
import { Footer } from '../../components/Footer';

export default function FrontPage() {
  return (
    <>
      <Welcome />
    </>
  );
}
