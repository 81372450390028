import React, { useCallback, useEffect, useState } from 'react';
import styles from './Welcome.module.scss';
import shopImage from '../../res/images/welcome/shop.jpg';
import { NavBar } from '../NavBar';
import { WelcomeDataProvider, IJobInfo } from '../../data';
import { TitleArea } from '../TitleArea';
import { JobCarousel } from '../JobCarousel';
import { ImageViewer } from '../ImageViewer';

export interface IWelcomeProps {
}

// TODO: take from some context or something.
const dataProvider : WelcomeDataProvider = new WelcomeDataProvider();

export default function Welcome(props: IWelcomeProps): JSX.Element {

  const [jobs, setJobs] = useState<IJobInfo[] | undefined>(undefined);
  const [selectedJob, setSelectedJob] = useState<IJobInfo | undefined>(undefined);
  const [imageViewerOpen, setImageViewerOpen] = useState<boolean>(false);

  const onImageViewerClose = useCallback(() => setImageViewerOpen(false), [setImageViewerOpen]);

  useEffect(() => {
    dataProvider.getJobs().then(setJobs);
  }, [setJobs]);

  const onJobSelected = useCallback((job: IJobInfo) => {
    setSelectedJob(job);
    setImageViewerOpen(true);
  }, [setSelectedJob, setImageViewerOpen]);

  return (
    <section className={styles.root}>
      <div className={styles.topSection}>
        <div className={styles.titleSection}>
          <TitleArea />
          <div className={styles.spacer} />
          <NavBar inline />
          <div className={styles.spacer} />
        </div>
        <div className={styles.shopImageWrapper}>
          <img 
            src={shopImage} 
            className={styles.shopImage}
          />
        </div>
      </div>
      <div className={styles.bottomSection}>
        {jobs && (
          <JobCarousel 
            jobs={jobs} 
            onJobSelected={onJobSelected}
          />
        )}
      </div>
      <ImageViewer 
        open={imageViewerOpen && !!selectedJob}
        onClose={onImageViewerClose}
        title={selectedJob?.jobTitle}
        images={selectedJob?.allImageUrls}
      />
    </section>
  );
}