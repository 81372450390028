import React from 'react';
import styles from './GivingBack.module.scss';
import { Section } from '../Section';

export interface IGivingBackProps{

}

export default function GivingBack(props: IGivingBackProps) {
  return (
    <Section>
      <h2 id="giving-back">Giving Back</h2>
      <p>
        At Canadian Charcuterie Company, we believe in the power of giving back. That's why we
        donate 10% of our proceeds to <a 
          href="https://connectingpointchurch.ca"
          target="_blank"
        >
          Connecting Point Church
        </a>.
        Connecting Point supports various ministries, including work dedicated to help those 
        broken by the strife of addiction and substance abuse. It was through
        this charitable work that Dwayne was able to find recovery and rebuild his life.
      </p>
      <p>
        Anyone who makes a purchase gets a free signed copy of Dwayne's 
        autobiography "From Reel to Real."
      </p>
    </Section>
  );
}