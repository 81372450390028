import React, { useCallback } from 'react';
import styles from './NotFoundPage.module.scss';
import { Footer } from '../../components/Footer';
import logo from '../../res/images/logo512.png';

export interface INotFoundPageProps {

}

export default function NotFoundPage(props: INotFoundPageProps) {

  const onClickBackToFrontPage = useCallback(() => {
    window.open('/');
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.contentContainer}>
        <h1>Page Not Found</h1>
        <p>
          We're sorry. It looks like we couldn't find the page to which you were trying to navigate.
        </p>
        <img 
          className={styles.logo}
          src={logo}
          alt=""
        />
        <a
          className={styles.frontPageButton}
          href="/"
        >
          Back to the Front Page
        </a>
      </div>
      <Footer />
    </div>

  );
}