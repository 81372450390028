import React from 'react';
import styles from './GivingBackPage.module.scss';
import { GivingBack } from '../../components/GivingBack';
import { Footer } from '../../components/Footer';
import { NavBar } from '../../components/NavBar';

export default function GivingBackPage() {
  return (
    <div className={styles.root}>
      <NavBar />
      <div className={styles.mainContent}>
        <GivingBack />
      </div>
      <Footer />
    </div>
  );
}