import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ImageViewer.module.scss';
import { Thumbnail } from './Thumbnail';
import { Image } from '../Image';

export interface IImageViewerImage {
  url: string;
  fallbackUrl?: string;
}

export interface IImageViewerProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  images?: string[];
}

export default function ImageViewer(props: IImageViewerProps) {
  const { title, images, open, onClose } = props;
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  const selectedImage : string | undefined = useMemo<string | undefined>(
    () => images?.[selectedImageIndex % images.length],
    [images, selectedImageIndex]
  );

  useEffect(() => {
    if (open) {
      setSelectedImageIndex(0);
    }
  }, [open]);

  const onImageSelected = useCallback((index: number): void => {
    setSelectedImageIndex(index);
  }, [setSelectedImageIndex]);

  return (
    <div className={`${styles.root} ${open ? styles.open : ''}`}>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <button className={styles.closeButton} onClick={onClose}>Close</button>
      </div>
      <div className={styles.body}>
        <div className={styles.mainContent}>
          <div className={styles.imageSelector}>
            {images?.map((image, index) => (
              <Thumbnail
                image={image}
                index={index}
                isSelected={index === selectedImageIndex}
                onSelected={onImageSelected}
              />
            ))}
          </div>
          <Image
            src={selectedImage}
            className={styles.selectedImage}
          />
        </div>
      </div>
    </div>
  );
}