import React from 'react';
import styles from './JobCard.module.scss';

export interface IJobCardProps {
  imageSrc: string;
  jobTitle: string;
  onJobSelected: () => void;
}

export default function JobCard(props: IJobCardProps) {
  const { imageSrc, jobTitle, onJobSelected } = props;

  return (
    <div 
      className={styles.root}
      onClick={onJobSelected}
    >
      <div className={styles.imageWrapper}>
        <img 
          src={imageSrc} 
          className={styles.image}
        />
      </div>
      <span className={styles.jobTitle}>
        {jobTitle}
      </span>
    </div>
  );
}