// This file was generated by ./normalize-images.py

import type IJobInfo from "./IJobInfo";
const jobInfos : IJobInfo[] = [
  {
    "id": "1A",
    "jobTitle": "Job #1A - Turqouise Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-1A/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-1A/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-1A/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-1A/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-1A/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-1A/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-1A/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-1A/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-1A/image_01.jpg",
      "/job-images/job-1A/image_02.jpg",
      "/job-images/job-1A/image_03.jpg",
      "/job-images/job-1A/image_04.jpg",
      "/job-images/job-1A/image_05.jpg",
      "/job-images/job-1A/image_06.jpg"
    ]
  },
  {
    "id": "1B",
    "jobTitle": "Job #1B - Purple Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-1B/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-1B/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-1B/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-1B/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-1B/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-1B/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-1B/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-1B/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-1B/image_01.jpg",
      "/job-images/job-1B/image_02.jpg",
      "/job-images/job-1B/image_03.jpg",
      "/job-images/job-1B/image_04.jpg",
      "/job-images/job-1B/image_05.jpg",
      "/job-images/job-1B/image_06.jpg"
    ]
  },
  {
    "id": "2",
    "jobTitle": "Job #2 - Turquoise Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-2/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-2/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-2/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-2/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-2/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-2/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-2/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-2/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-2/image_01.jpg",
      "/job-images/job-2/image_02.jpg",
      "/job-images/job-2/image_03.jpg",
      "/job-images/job-2/image_04.jpg",
      "/job-images/job-2/image_05.jpg",
      "/job-images/job-2/image_06.jpg"
    ]
  },
  {
    "id": "3",
    "jobTitle": "Job #3 -  Blue and Teal Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-3/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-3/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-3/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-3/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-3/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-3/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-3/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-3/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-3/image_01.jpg",
      "/job-images/job-3/image_02.jpg",
      "/job-images/job-3/image_03.jpg",
      "/job-images/job-3/image_04.jpg",
      "/job-images/job-3/image_05.jpg",
      "/job-images/job-3/image_06.jpg"
    ]
  },
  {
    "id": "4",
    "jobTitle": "Job #4 - Gold-Copper Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-4/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-4/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-4/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-4/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-4/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-4/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-4/image_06.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-4/image_07.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-4/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-4/image_01.jpg",
      "/job-images/job-4/image_02.jpg",
      "/job-images/job-4/image_03.jpg",
      "/job-images/job-4/image_04.jpg",
      "/job-images/job-4/image_05.jpg",
      "/job-images/job-4/image_06.jpg",
      "/job-images/job-4/image_07.jpg"
    ]
  },
  {
    "id": "5",
    "jobTitle": "Job #5 - Clear White Resin Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-5/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-5/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-5/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-5/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-5/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-5/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-5/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-5/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-5/image_01.jpg",
      "/job-images/job-5/image_02.jpg",
      "/job-images/job-5/image_03.jpg",
      "/job-images/job-5/image_04.jpg",
      "/job-images/job-5/image_05.jpg",
      "/job-images/job-5/image_06.jpg"
    ]
  },
  {
    "id": "6",
    "jobTitle": "Job #6 - Funky Long Blue Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-6/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-6/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-6/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-6/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-6/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-6/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-6/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-6/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-6/image_01.jpg",
      "/job-images/job-6/image_02.jpg",
      "/job-images/job-6/image_03.jpg",
      "/job-images/job-6/image_04.jpg",
      "/job-images/job-6/image_05.jpg",
      "/job-images/job-6/image_06.jpg"
    ]
  },
  {
    "id": "7",
    "jobTitle": "Job #7 - Teal-Pearl White Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-7/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-7/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-7/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-7/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-7/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-7/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-7/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-7/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-7/image_01.jpg",
      "/job-images/job-7/image_02.jpg",
      "/job-images/job-7/image_03.jpg",
      "/job-images/job-7/image_04.jpg",
      "/job-images/job-7/image_05.jpg",
      "/job-images/job-7/image_06.jpg"
    ]
  },
  {
    "id": "8",
    "jobTitle": "Job #8 - Classic Gold Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-8/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-8/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-8/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-8/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-8/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-8/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-8/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-8/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-8/image_01.jpg",
      "/job-images/job-8/image_02.jpg",
      "/job-images/job-8/image_03.jpg",
      "/job-images/job-8/image_04.jpg",
      "/job-images/job-8/image_05.jpg",
      "/job-images/job-8/image_06.jpg"
    ]
  },
  {
    "id": "9",
    "jobTitle": "Job #9 - Clear-Black Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-9/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-9/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-9/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-9/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-9/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-9/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-9/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-9/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-9/image_01.jpg",
      "/job-images/job-9/image_02.jpg",
      "/job-images/job-9/image_03.jpg",
      "/job-images/job-9/image_04.jpg",
      "/job-images/job-9/image_05.jpg",
      "/job-images/job-9/image_06.jpg"
    ]
  },
  {
    "id": "10",
    "jobTitle": "Job #10 - Clear-Black Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-10/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-10/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-10/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-10/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-10/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-10/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-10/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-10/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-10/image_01.jpg",
      "/job-images/job-10/image_02.jpg",
      "/job-images/job-10/image_03.jpg",
      "/job-images/job-10/image_04.jpg",
      "/job-images/job-10/image_05.jpg",
      "/job-images/job-10/image_06.jpg"
    ]
  },
  {
    "id": "11",
    "jobTitle": "Job #11 - Red Wine Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-11/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-11/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-11/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-11/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-11/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-11/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-11/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-11/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-11/image_01.jpg",
      "/job-images/job-11/image_02.jpg",
      "/job-images/job-11/image_03.jpg",
      "/job-images/job-11/image_04.jpg",
      "/job-images/job-11/image_05.jpg",
      "/job-images/job-11/image_06.jpg"
    ]
  },
  {
    "id": "12",
    "jobTitle": "Job #12 - Flat Black Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-12/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-12/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-12/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-12/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-12/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-12/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-12/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-12/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-12/image_01.jpg",
      "/job-images/job-12/image_02.jpg",
      "/job-images/job-12/image_03.jpg",
      "/job-images/job-12/image_04.jpg",
      "/job-images/job-12/image_05.jpg",
      "/job-images/job-12/image_06.jpg"
    ]
  },
  {
    "id": "13",
    "jobTitle": "Job #13 - Turquoise Coffee Table",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-13/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-13/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-13/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-13/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-13/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-13/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-13/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-13/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-13/image_01.jpg",
      "/job-images/job-13/image_02.jpg",
      "/job-images/job-13/image_03.jpg",
      "/job-images/job-13/image_04.jpg",
      "/job-images/job-13/image_05.jpg",
      "/job-images/job-13/image_06.jpg"
    ]
  },
  {
    "id": "14",
    "jobTitle": "Job #14 - Red Thin Long Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-14/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-14/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-14/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-14/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-14/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-14/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-14/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-14/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-14/image_01.jpg",
      "/job-images/job-14/image_02.jpg",
      "/job-images/job-14/image_03.jpg",
      "/job-images/job-14/image_04.jpg",
      "/job-images/job-14/image_05.jpg",
      "/job-images/job-14/image_06.jpg"
    ]
  },
  {
    "id": "15",
    "jobTitle": "Job #15 - Black Thin Long Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-15/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-15/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-15/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-15/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-15/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-15/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-15/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-15/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-15/image_01.jpg",
      "/job-images/job-15/image_02.jpg",
      "/job-images/job-15/image_03.jpg",
      "/job-images/job-15/image_04.jpg",
      "/job-images/job-15/image_05.jpg",
      "/job-images/job-15/image_06.jpg"
    ]
  },
  {
    "id": "16",
    "jobTitle": "Job #16 - Bright Blue Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-16/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-16/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-16/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-16/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-16/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-16/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-16/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-16/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-16/image_01.jpg",
      "/job-images/job-16/image_02.jpg",
      "/job-images/job-16/image_03.jpg",
      "/job-images/job-16/image_04.jpg",
      "/job-images/job-16/image_05.jpg",
      "/job-images/job-16/image_06.jpg"
    ]
  },
  {
    "id": "17",
    "jobTitle": "Job #17 - Red 3 Knot Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-17/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-17/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-17/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-17/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-17/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-17/image_05.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-17/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-17/image_01.jpg",
      "/job-images/job-17/image_02.jpg",
      "/job-images/job-17/image_03.jpg",
      "/job-images/job-17/image_04.jpg",
      "/job-images/job-17/image_05.jpg"
    ]
  },
  {
    "id": "18",
    "jobTitle": "Job #18 - Blue Gemstone Runner Table",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-18/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-18/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-18/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-18/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-18/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-18/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-18/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-18/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-18/image_01.jpg",
      "/job-images/job-18/image_02.jpg",
      "/job-images/job-18/image_03.jpg",
      "/job-images/job-18/image_04.jpg",
      "/job-images/job-18/image_05.jpg",
      "/job-images/job-18/image_06.jpg"
    ]
  },
  {
    "id": "19",
    "jobTitle": "Job #19 - Rock River Turqouise Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-19/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-19/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-19/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-19/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-19/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-19/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-19/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-19/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-19/image_01.jpg",
      "/job-images/job-19/image_02.jpg",
      "/job-images/job-19/image_03.jpg",
      "/job-images/job-19/image_04.jpg",
      "/job-images/job-19/image_05.jpg",
      "/job-images/job-19/image_06.jpg"
    ]
  },
  {
    "id": "20",
    "jobTitle": "Job #20 - Rock River Blue Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-20/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-20/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-20/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-20/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-20/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-20/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-20/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-20/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-20/image_01.jpg",
      "/job-images/job-20/image_02.jpg",
      "/job-images/job-20/image_03.jpg",
      "/job-images/job-20/image_04.jpg",
      "/job-images/job-20/image_05.jpg",
      "/job-images/job-20/image_06.jpg"
    ]
  },
  {
    "id": "21",
    "jobTitle": "Job #21 - Black Coffee Table",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-21/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-21/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-21/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-21/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-21/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-21/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-21/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-21/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-21/image_01.jpg",
      "/job-images/job-21/image_02.jpg",
      "/job-images/job-21/image_03.jpg",
      "/job-images/job-21/image_04.jpg",
      "/job-images/job-21/image_05.jpg",
      "/job-images/job-21/image_06.jpg"
    ]
  },
  {
    "id": "22",
    "jobTitle": "Job #22 -  Long Black Swirl Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-22/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-22/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-22/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-22/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-22/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-22/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-22/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-22/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-22/image_01.jpg",
      "/job-images/job-22/image_02.jpg",
      "/job-images/job-22/image_03.jpg",
      "/job-images/job-22/image_04.jpg",
      "/job-images/job-22/image_05.jpg",
      "/job-images/job-22/image_06.jpg"
    ]
  },
  {
    "id": "23",
    "jobTitle": "Job #23 - Clear Resin Coffee Table",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-23/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-23/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-23/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-23/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-23/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-23/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-23/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-23/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-23/image_01.jpg",
      "/job-images/job-23/image_02.jpg",
      "/job-images/job-23/image_03.jpg",
      "/job-images/job-23/image_04.jpg",
      "/job-images/job-23/image_05.jpg",
      "/job-images/job-23/image_06.jpg"
    ]
  },
  {
    "id": "24",
    "jobTitle": "Job #24 - Long Purple Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-24/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-24/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-24/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-24/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-24/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-24/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-24/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-24/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-24/image_01.jpg",
      "/job-images/job-24/image_02.jpg",
      "/job-images/job-24/image_03.jpg",
      "/job-images/job-24/image_04.jpg",
      "/job-images/job-24/image_05.jpg",
      "/job-images/job-24/image_06.jpg"
    ]
  },
  {
    "id": "25",
    "jobTitle": "Job #25 - Long Blue Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-25/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-25/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-25/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-25/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-25/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-25/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-25/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-25/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-25/image_01.jpg",
      "/job-images/job-25/image_02.jpg",
      "/job-images/job-25/image_03.jpg",
      "/job-images/job-25/image_04.jpg",
      "/job-images/job-25/image_05.jpg",
      "/job-images/job-25/image_06.jpg"
    ]
  },
  {
    "id": "26",
    "jobTitle": "Job #26 - Long Teal Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-26/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-26/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-26/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-26/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-26/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-26/image_05.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-26/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-26/image_01.jpg",
      "/job-images/job-26/image_02.jpg",
      "/job-images/job-26/image_03.jpg",
      "/job-images/job-26/image_04.jpg",
      "/job-images/job-26/image_05.jpg"
    ]
  },
  {
    "id": "27",
    "jobTitle": "Job #27 - Long Pointed Purple Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-27/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-27/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-27/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-27/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-27/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-27/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-27/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-27/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-27/image_01.jpg",
      "/job-images/job-27/image_02.jpg",
      "/job-images/job-27/image_03.jpg",
      "/job-images/job-27/image_04.jpg",
      "/job-images/job-27/image_05.jpg",
      "/job-images/job-27/image_06.jpg"
    ]
  },
  {
    "id": "28",
    "jobTitle": "Job #28 - Long Pointed Turqouise Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-28/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-28/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-28/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-28/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-28/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-28/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-28/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-28/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-28/image_01.jpg",
      "/job-images/job-28/image_02.jpg",
      "/job-images/job-28/image_03.jpg",
      "/job-images/job-28/image_04.jpg",
      "/job-images/job-28/image_05.jpg",
      "/job-images/job-28/image_06.jpg"
    ]
  },
  {
    "id": "29",
    "jobTitle": "Job #29 - Gold Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-29/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-29/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-29/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-29/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-29/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-29/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-29/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-29/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-29/image_01.jpg",
      "/job-images/job-29/image_02.jpg",
      "/job-images/job-29/image_03.jpg",
      "/job-images/job-29/image_04.jpg",
      "/job-images/job-29/image_05.jpg",
      "/job-images/job-29/image_06.jpg"
    ]
  },
  {
    "id": "30",
    "jobTitle": "Job #30 - Black Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-30/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-30/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-30/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-30/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-30/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-30/image_05.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-30/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-30/image_01.jpg",
      "/job-images/job-30/image_02.jpg",
      "/job-images/job-30/image_03.jpg",
      "/job-images/job-30/image_04.jpg",
      "/job-images/job-30/image_05.jpg"
    ]
  },
  {
    "id": "31",
    "jobTitle": "Job #31 - Turqouise Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-31/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-31/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-31/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-31/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-31/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-31/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-31/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-31/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-31/image_01.jpg",
      "/job-images/job-31/image_02.jpg",
      "/job-images/job-31/image_03.jpg",
      "/job-images/job-31/image_04.jpg",
      "/job-images/job-31/image_05.jpg",
      "/job-images/job-31/image_06.jpg"
    ]
  },
  {
    "id": "32",
    "jobTitle": "Job #32 - Grinch Clear Resin Black Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-32/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-32/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-32/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-32/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-32/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-32/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-32/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-32/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-32/image_01.jpg",
      "/job-images/job-32/image_02.jpg",
      "/job-images/job-32/image_03.jpg",
      "/job-images/job-32/image_04.jpg",
      "/job-images/job-32/image_05.jpg",
      "/job-images/job-32/image_06.jpg"
    ]
  },
  {
    "id": "33",
    "jobTitle": "Job #33 - Grinch Blue",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-33/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-33/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-33/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-33/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-33/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-33/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-33/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-33/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-33/image_01.jpg",
      "/job-images/job-33/image_02.jpg",
      "/job-images/job-33/image_03.jpg",
      "/job-images/job-33/image_04.jpg",
      "/job-images/job-33/image_05.jpg",
      "/job-images/job-33/image_06.jpg"
    ]
  },
  {
    "id": "34",
    "jobTitle": "Job #34 - Grinch Blue Two",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-34/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-34/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-34/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-34/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-34/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-34/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-34/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-34/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-34/image_01.jpg",
      "/job-images/job-34/image_02.jpg",
      "/job-images/job-34/image_03.jpg",
      "/job-images/job-34/image_04.jpg",
      "/job-images/job-34/image_05.jpg",
      "/job-images/job-34/image_06.jpg"
    ]
  },
  {
    "id": "35",
    "jobTitle": "Job #35 - Gold Oval Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-35/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-35/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-35/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-35/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-35/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-35/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-35/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-35/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-35/image_01.jpg",
      "/job-images/job-35/image_02.jpg",
      "/job-images/job-35/image_03.jpg",
      "/job-images/job-35/image_04.jpg",
      "/job-images/job-35/image_05.jpg",
      "/job-images/job-35/image_06.jpg"
    ]
  },
  {
    "id": "36",
    "jobTitle": "Job #36 - Black Round Top Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-36/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-36/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-36/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-36/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-36/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-36/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-36/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-36/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-36/image_01.jpg",
      "/job-images/job-36/image_02.jpg",
      "/job-images/job-36/image_03.jpg",
      "/job-images/job-36/image_04.jpg",
      "/job-images/job-36/image_05.jpg",
      "/job-images/job-36/image_06.jpg"
    ]
  },
  {
    "id": "37",
    "jobTitle": "Job #37 - Black Long Runner Table",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-37/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-37/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-37/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-37/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-37/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-37/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-37/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-37/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-37/image_01.jpg",
      "/job-images/job-37/image_02.jpg",
      "/job-images/job-37/image_03.jpg",
      "/job-images/job-37/image_04.jpg",
      "/job-images/job-37/image_05.jpg",
      "/job-images/job-37/image_06.jpg"
    ]
  },
  {
    "id": "38",
    "jobTitle": "Job #38 - Blue River Charcuterie Board",
    "thumbnailImageUrl": "https://cdncharpush-20acb.kxcdn.com/job-images/job-38/thumbnail.jpg",
    "allImageUrls": [
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-38/image_01.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-38/image_02.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-38/image_03.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-38/image_04.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-38/image_05.jpg",
      "https://cdncharpush-20acb.kxcdn.com/job-images/job-38/image_06.jpg"
    ],
    "altThumbnailImageUrl": "/job-images/job-38/thumbnail.jpg",
    "altAllImageUrls": [
      "/job-images/job-38/image_01.jpg",
      "/job-images/job-38/image_02.jpg",
      "/job-images/job-38/image_03.jpg",
      "/job-images/job-38/image_04.jpg",
      "/job-images/job-38/image_05.jpg",
      "/job-images/job-38/image_06.jpg"
    ]
  }
];
export default jobInfos;
