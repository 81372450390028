import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { FrontPage, NotFoundPage, AboutPage, GivingBackPage, ContactPage } from './pages';

function App() {
  return (
    <Routes>
      <Route path="/" element={<FrontPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/giving-back" element={<GivingBackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
