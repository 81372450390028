import React from 'react';
import styles from './Footer.module.scss';

export interface IFooterProps {

}

export default function Footer(props: IFooterProps) {
  return (
    <footer className={styles.root}>
      &copy;2024 Canadian Charcuterie Company
    </footer>
  );
}